<script>
export default {
  name: "FooterBlock",
  components: {},
  data() {
    return {
      links: [
        {
          id: 1,
          name: "Компания",
          link: "#",
          subLinks: [
            {
              id: 1,
              name: "Новости",
              link: "/news",
            },
            {
              id: 2,
              name: "Обучение",
              link: "/training",
            },
            {
              id: 3,
              name: "О компании",
              link: "/about",
            },
            {
              id: 4,
              name: "Контакты",
              link: "/contacts",
            },
            {
              id: 5,
              name: "Реквизиты",
              link: "/rekvizites",
            },
            {
              id: 6,
              name: "Политика конфиденциальности",
              link: "/politic",
            },
            {
              id: 7,
              name: "Пользовательское соглашение",
              link: "/agreement",
            },
          ],
        },
        {
          id: 2,
          name: "Каталог",
          link: "/shop",
          subLinks: [
            {
              id: 1,
              name: "Трековые",
              link: "/shop",
            },
            {
              id: 2,
              name: "Подвесные",
              link: "/shop",
            },
            {
              id: 3,
              name: "Накладные",
              link: "/shop",
            },
            {
              id: 4,
              name: "Встраиваемые",
              link: "/shop",
            },
            {
              id: 5,
              name: "Светодиодные ленты",
              link: "/shop",
            },
            {
              id: 6,
              name: "Блоки питания",
              link: "/shop",
            },
          ],
        },
        {
          id: 3,
          name: "Контакты",
          link: "#",
          subLinks: [
            {
              id: 1,
              name: "8 (928) 422-12-21",
              link: "tel:+84723384221",
              icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M18.3332 14.1014V16.6014C18.3341 16.8335 18.2866 17.0632 18.1936 17.2758C18.1006 17.4885 17.9643 17.6794 17.7933 17.8363C17.6222 17.9932 17.4203 18.1126 17.2005 18.187C16.9806 18.2613 16.7477 18.2889 16.5165 18.268C13.9522 17.9894 11.489 17.1132 9.32486 15.7097C7.31139 14.4303 5.60431 12.7232 4.32486 10.7097C2.91651 8.53572 2.04007 6.06054 1.76653 3.48472C1.7457 3.25427 1.77309 3.02202 1.84695 2.80274C1.9208 2.58346 2.03951 2.38196 2.1955 2.21107C2.3515 2.04018 2.54137 1.90364 2.75302 1.81015C2.96468 1.71666 3.19348 1.66827 3.42486 1.66805H5.92486C6.32928 1.66407 6.72136 1.80728 7.028 2.07099C7.33464 2.33471 7.53493 2.70092 7.59153 3.10138C7.69705 3.90144 7.89274 4.68699 8.17486 5.44305C8.28698 5.74132 8.31125 6.06548 8.24478 6.37712C8.17832 6.68875 8.02392 6.97481 7.79986 7.20138L6.74153 8.25972C7.92783 10.346 9.65524 12.0734 11.7415 13.2597L12.7999 12.2014C13.0264 11.9773 13.3125 11.8229 13.6241 11.7565C13.9358 11.69 14.2599 11.7143 14.5582 11.8264C15.3143 12.1085 16.0998 12.3042 16.8999 12.4097C17.3047 12.4668 17.6744 12.6707 17.9386 12.9826C18.2029 13.2945 18.3433 13.6927 18.3332 14.1014Z" stroke="#D9D9D9" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
              id: 2,
              name: "info@lumatica.ru",
              link: "mailto:info@lumatica.ru",
              icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M18.3332 5.83203L10.8582 10.582C10.6009 10.7432 10.3034 10.8287 9.99984 10.8287C9.69624 10.8287 9.39878 10.7432 9.1415 10.582L1.6665 5.83203M3.33317 3.33203H16.6665C17.587 3.33203 18.3332 4.07822 18.3332 4.9987V14.9987C18.3332 15.9192 17.587 16.6654 16.6665 16.6654H3.33317C2.4127 16.6654 1.6665 15.9192 1.6665 14.9987V4.9987C1.6665 4.07822 2.4127 3.33203 3.33317 3.33203Z" stroke="#D9D9D9" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
              id: 3,
              name: "telegram",
              link: "https://t.me/lumaticaru",
              icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M16.8525 3.09205L1.7938 8.79416C0.766098 9.19948 0.772044 9.76244 1.60524 10.0135L5.47142 11.1978L14.4166 5.65579C14.8396 5.40309 15.226 5.53903 14.9084 5.81592L7.66101 12.2386H7.65931L7.66101 12.2394L7.39432 16.1526C7.78501 16.1526 7.95743 15.9766 8.17656 15.769L10.0544 13.9758L13.9605 16.809C14.6808 17.1984 15.198 16.9983 15.3772 16.1543L17.9414 4.28802C18.2038 3.25468 17.5396 2.78681 16.8525 3.09205Z" fill="#D9D9D9"/></svg>',
            },
            {
              id: 4,
              name: "VK",
              link: "https://vk.com/lumatica",
              icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M2.26522 2.26522C1 3.53053 1 5.56718 1 9.64V10.36C1 14.4329 1 16.4695 2.26522 17.7347C3.53053 19 5.56718 19 9.64 19H10.36C14.4329 19 16.4695 19 17.7347 17.7347C19 16.4695 19 14.4329 19 10.36V9.64C19 5.56714 19 3.53049 17.7347 2.26522C16.4695 1 14.4329 1 10.36 1H9.64C5.56714 1 3.53049 1 2.26522 2.26522ZM4.0375 6.47505H6.09251C6.16001 9.91004 7.67499 11.365 8.875 11.665V6.47501H10.81V9.4375C11.995 9.31001 13.24 7.96001 13.66 6.47501H15.595C15.4367 7.24516 15.1212 7.97436 14.6682 8.61702C14.2153 9.25969 13.6346 9.80199 12.9625 10.21C13.7129 10.5826 14.3757 11.1101 14.9071 11.7579C15.4385 12.4056 15.8263 13.1587 16.045 13.9675H13.915C13.7187 13.265 13.3194 12.6361 12.767 12.1597C12.2145 11.6834 11.5337 11.3808 10.81 11.29V13.9675H10.5775C6.47501 13.9675 4.13501 11.155 4.0375 6.47501" fill="#D9D9D9"/></svg>',
            },
            {
              id: 5,
              name: "youtube",
              link: "https://youtube.com/@lumatica.showroom?si=JfoWdtl537y7IPjx",
              icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M19.5826 5.18694C19.4687 4.76346 19.2455 4.37727 18.9353 4.06702C18.625 3.75677 18.2386 3.53335 17.8147 3.41911C16.2545 3 10 3 10 3C10 3 3.74554 3 2.18527 3.41688C1.76121 3.53075 1.37461 3.75405 1.0643 4.06436C0.753989 4.37466 0.530891 4.76105 0.417411 5.18471C-8.51495e-08 6.74522 0 10 0 10C0 10 -8.51495e-08 13.2548 0.417411 14.8131C0.647321 15.6736 1.32589 16.3513 2.18527 16.5809C3.74554 17 10 17 10 17C10 17 16.2545 17 17.8147 16.5809C18.6763 16.3513 19.3527 15.6736 19.5826 14.8131C20 13.2548 20 10 20 10C20 10 20 6.74522 19.5826 5.18694ZM8.01339 12.9873V7.01274L13.192 9.97771L8.01339 12.9873Z" fill="#D9D9D9"/></svg>',
            },
          ],
        },
      ],
    };
  },
  methods: {
    parseHTML(id, icon) {

      document.getElementById(id).innerHTML = icon;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.links[2].subLinks.forEach((sublink) => {
        if (sublink.icon) {
          this.parseHTML("sublink" + sublink.id, sublink.icon);
        }
      });
    });
  },
};
</script>

<template>
  <div class="sector">
    <div class="footer">
      <div class="container-footer">
        <svg
          class="triangle"
          width="56.000000"
          height="54.000000"
          viewBox="0 0 56 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs />
          <path
            id="Vector"
            d="M56 0L56 54L54.5607 52.6092L53.6722 51.7562L38.5505 37.1714L14.6242 14.1027L1.67921 1.62259L0.71965 0.695404L0 0L56 0Z"
            fill="#FFFFFF"
            fill-opacity="1.000000"
            fill-rule="nonzero"
          />
        </svg>
        <img
          class="topVector absoluteVector"
          src="@/assets/image/Top (Desktop HD).svg"
          alt=""
        />
        <img
          class="leftVector absoluteVector"
          src="@/assets/image/Left (Desktop HD).svg"
          alt=""
        />
        <img
          class="rightVector absoluteVector"
          src="@/assets/image/Right (Desktop HD).svg"
          alt=""
        />
        <img
          class="bottomVector absoluteVector"
          src="@/assets/image/Bottom (Desktop HD).svg"
          alt=""
        />
        <div class="footer__content">
          <div class="logo">
            <img class="logo-image" src="@/assets/image/logo.svg" alt="" />
            <div class="footer__date">2024</div>
          </div>
          <div class="nav-links">
            <div class="col" v-for="link in links" :key="link.id">
              <a :href="link.link" class="boss__link">{{ link.name }}</a>
              <div class="sublinks">
                <a
                  v-for="sublink in link.subLinks"
                  :key="sublink.id"
                  :href="sublink.link"
                  class="sublink"
                  ><t v-if="sublink.icon" :id="'sublink' + sublink.id"></t>
                  {{ sublink.name }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="descriptionBlock">
      <div class="container">
        <div class="description__links">
          <div class="politics">
            
          </div>
          <a href="https://creatin.ru" class="creatin">
            <p class="creatin__text">разработано</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="15"
              viewBox="0 0 65 15"
              fill="none"
            >
              <path
                d="M6.47952 14.1329C3.36011 14.1329 0.828125 11.5139 0.828125 8.2968C0.828125 4.91475 3.5019 2.50195 6.47952 2.50195C8.28229 2.50195 9.94328 3.40933 10.8548 4.74978L8.80895 6.77075C8.32281 5.80151 7.28976 5.38906 6.49978 5.38906C5.08186 5.38906 3.66395 6.50267 3.66395 8.2968C3.66395 9.92595 4.91982 11.2458 6.49978 11.2458C7.51257 11.2458 8.44434 10.6477 8.8292 9.84347L10.8548 11.8644C9.96353 13.2255 8.28229 14.1329 6.47952 14.1329Z"
                fill="white"
              />
              <path
                d="M12.0586 14.1335V3.31142H14.7346V4.56557C15.2376 3.59462 16.7869 3.25074 18.0746 3.31142V6.14337C16.4448 5.86017 14.8755 6.89181 14.8755 8.59097V14.1335H12.0586Z"
                fill="white"
              />
              <path
                d="M24.5078 14.1342C21.1418 14.1342 18.877 11.2666 18.877 8.49923C18.877 5.61158 20.8509 2.88435 24.2377 2.90441C27.9363 2.92446 30.5128 5.7319 29.4738 9.74253H22.056C22.2015 10.7051 23.3858 11.6275 24.7987 11.6275C25.5675 11.6476 26.8973 11.3067 27.2714 10.8053L29.4738 12.4296C28.3518 13.6529 26.5441 14.1342 24.5078 14.1342ZM22.0352 7.3161H26.6272C26.7311 6.59418 26.1286 5.37094 24.3416 5.37094C22.804 5.37094 22.0352 6.45381 22.0352 7.3161Z"
                fill="white"
              />
              <path
                d="M35.7306 14.0535C32.6236 14.0535 30.1055 11.5535 30.1055 8.46883C30.1055 5.4043 32.6236 2.9043 35.7306 2.9043C38.8172 2.9043 41.3353 5.4043 41.3353 8.46883V14.1342H38.4923V12.8035C37.8628 13.5495 36.8678 14.0535 35.7306 14.0535ZM35.7306 11.2309C37.2536 11.2309 38.4923 10.0011 38.4923 8.46883C38.4923 6.95673 37.2536 5.72689 35.7306 5.72689C34.1872 5.72689 32.9485 6.95673 32.9485 8.46883C32.9485 10.0011 34.1872 11.2309 35.7306 11.2309Z"
                fill="white"
              />
              <path
                d="M48.5545 14.1336C46.275 14.1336 42.7248 13.5218 42.7248 9.56556V6.01714H41.7363V3.16209H42.7248V0.898438H45.5488V3.16209H48.1712V6.01714H45.5488V8.93337C45.5488 11.0747 47.1223 11.2786 48.5545 11.2786V14.1336Z"
                fill="white"
              />
              <path
                d="M50.1602 14.1335V3.30469H52.9676V14.1335H50.1602Z"
                fill="white"
              />
              <path
                d="M54.1699 14.1342V8.44711C54.1699 5.19148 56.4904 2.9043 59.5843 2.9043C62.6579 2.9043 64.9987 5.19148 64.9987 8.44711V14.1342H62.149V8.44711C62.149 6.77809 61.1109 5.78903 59.5843 5.78903C58.0577 5.78903 57.0196 6.77809 57.0196 8.44711V14.1342H54.1699Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer {
  background: var(--Black-1300, #1d1d1b);
  height: 367px;
  padding: 30px;
}
.footer__content {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.logo {
  display: flex;
  align-items: flex-end;
  gap: 54px;
  align-self: flex-end;
}
.logo-image {
  transform: translateY(8px);
}
.nav-links {
  display: flex;
  gap: 116px;
}
.col {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.boss__link {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sublinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sublink {
  color: var(--Black-400, #d9d9d9);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}
.sublink:nth-last-of-type(1), .sublink:nth-last-of-type(2){
  white-space: inherit;
}
.footer__date {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 17px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.triangle {
  position: absolute;
  top: 0;
  right: 0;
}
.container-footer {
  max-width: 1860px;
  padding: 43px 186px 7px 0px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.absoluteVector {
  position: absolute;
  z-index: 2;
  object-fit: cover;
}
.topVector {
  top: 0;
  left: 0px;
  max-width: calc(100% - 99px);
  height: 1px;
}
.leftVector {
  top: 0;
  left: 0;
  max-height: calc(100% - 146.24px);
  width: 1px;
}
.rightVector {
  bottom: 0;
  right: 0;
  max-height: calc(100% - 96.5px);
  width: 1px;
}
.bottomVector {
  bottom: 0;
  right: 0;
  max-width: calc(100% - 623.77px);
  height: 1px;
}
.descriptionBlock {
  background: #0e0e0e;
  padding: 16px 0;
}
.description__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.politics {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.politic__link {
  color: #6f6f6f;
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}
.creatin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}
.creatin__text {
  color: var(--Whiteboard-600, #979797);
  font-family: Jeko-SemiBold;
  font-size: 7.883px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1690px) {
  .nav-links {
    gap: 75px;
  }
}
@media (max-width: 1440px) {
  .container-footer {
    padding: 43px 100px 7px 0px;
  }
  .logo-image {
    width: 494px;
  }
  .footer__date {
    font-size: 14px;
  }
  .bottomVector {
    max-width: calc(100% - 547.77px);
    height: 1px;
  }
}
@media (max-width: 1024px) {
  .head-h1 svg {
    width: 283px;
    height: 47.56px;
  }

  .head-h1 {
    transform: translate(0px, 0px);
  }
  .container {
    padding: 20px;
  }
  .contacts {
    gap: 16px;
  }

  .nav-links {
    gap: 44px;
  }
  .container-footer {
    padding: 27px 20px 7px 0px;
  }
  .boss__link {
    font-size: 14px;
  }
  .sublink {
    font-size: 13px;
  }
  .logo-image {
    width: 253px;
  }
  .footer__date {
    font-size: 7.9px;
  }
  .logo {
    gap: 24px;
  }
  .bottomVector {
    max-width: calc(100% - 275.77px);
  }
  .footer {
    height: 278px;
  }
}
@media screen and (max-width: 650px) {
  .footer__content {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .logo {
    align-self: flex-start;
  }
  .col:nth-of-type(2) {
    order: 1;
    width: 100%;
  }
  .col:nth-of-type(1) {
    order: 2;
    width: calc(50% - 5px);
  }
  .col:nth-of-type(3) {
    order: 3;
    width: calc(50% - 5px);
  }
  .nav-links {
    flex-wrap: wrap;
    gap: 50px 10px;
    justify-content: space-between;
  }
  .footer {
    height: auto;
    padding: 17.52px 10px;
  }
  .col:nth-of-type(2) .sublinks {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .col:nth-of-type(2) .sublink {
    width: calc(50% - 5px);
  }
  .container-footer {
    padding: 40px 12px 0;
  }
  .logo-image {
    transform: translate(-17px, 1px);
  }
  .leftVector {
    max-height: calc(100% - 58.24px);
  }
  .footer__date {
    display: none;
  }
  .boss__link {
    font-size: 24px;
  }
  .sublink {
    font-size: 15px;
  }
}
</style>