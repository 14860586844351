<script>
import gsap from "gsap";
import Tr from "@/i18n/translation";
export default {
  name: "WidjetCart",
  components: {},
  props: {
    msg: {
      type: String,
    },

  },
  data() {
    return {
      Tr: Tr,
    };
  },
  mounted() {
    console.log("msg", this.msg);
    
    gsap.set(".widjet-cart", {
      opacity: 0,
      scale: 0,
    });
    gsap.to(".widjet-cart", {
      opacity: 1,
      scale: 1.05,
      duration: 0.5,
      delay: 0.5,
      ease: "elastic",
    });
    gsap.to(".widjet-cart", {
      opacity: 1,
      scale: 1,
      duration: 0.5,
      delay: 0.6,
      ease: "elastic",
    });
    gsap.to(".widjet-cart", {
      opacity: 0,
      scale: 0,
      duration: 1.5,
      delay: 4,
      ease: "elastic",
      onComplete: () => {
        localStorage.removeItem("addCart");
        localStorage.removeItem("addWishlistNot");
        localStorage.removeItem("addWishlist");
      }
    });
  },
};
</script>

<template>
  <div class="widjet-cart">
    <div v-if="msg === 'cart'" class="text">
      Товар успешно добавлен в
      <router-link :to="Tr.i18nRoute({ name: 'Cart' })">корзину</router-link>
    </div>
    <div v-if="msg === 'addWishlistNot'" class="text">
      Что бы добавить товар в избранное,
      <router-link :to="Tr.i18nRoute({ name: 'Login' })">войдите</router-link>
    </div>
    <div v-if="msg === 'addWishlist'" class="text">
      Товар успешно добавлен в
      <router-link :to="Tr.i18nRoute({ name: 'Wishlist' })">избранное</router-link>
    </div>
  </div>
</template>

<style>
.widjet-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  left: 50px;
  width: max-content;
  padding: 18px 24px;
  background: var(--Black, #111);
  z-index: 1000;
}
.text {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.widjet-cart a {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  text-decoration: underline;
}
</style>