<script>
import btnLink from "../ui/btnLink.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  name: "HomeSpecialBlock",
  components: {
    btnLink,
  },
  data() {
    return {
      timeline: null,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.timeline = gsap.timeline();
    this.timeline.fromTo(
      ".home-special-block",
      { y: "50%", opacity: 0.2 },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: ".home-special-block",
          start: "top bottom",
          end: "top center",
          scrub: 2,
        },
      }
    );
  },
};
</script>

<template>
  <div class="home-special-block sector-mt">
    <div class="container">
      <div class="home-special-block__content">
        <div class="home-special-block__head">
          <div class="head-social">Искусство выбора светильников с опытом</div>
          <p class="description">
            Добро пожаловать в мир светотехники, где каждый элемент создает
            атмосферу уюта и роскоши
          </p>
          <btnLink :link="'Training'" style="margin-top: 24px; width: max-content"
            >{{ $t("home.home-special-block.btn") }}</btnLink
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-special-block {
  background: url("../../assets/image/home/bg-social.png") no-repeat;
  background-position: top right;
  background-color: #eaeaea;
  padding: 99px 0;
}
.home-special-block__content {
  display: flex;
  flex-direction: column;
  grid-template: 20px;
}
.head-social {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 683px;
}
.description {
  max-width: 460px;
}
.home-special-block__head{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media screen and (max-width: 1024px) {
  .head-social {
    font-size: 26px;
    max-width: 339px;
  }
  .description {
      max-width: 358px;
  }
  .home-special-block {
    padding: 36px 0;
    background-size: 78%;
    background-position: 161% 37%;
  }
  .home-special-block__head{
    gap: 12px;
  }
}
@media screen and (max-width: 650px) {
    .head-social{
        font-size: 17px;
        max-width: 211px;
    }
    .home-special-block__head{
        gap: 10px;
    }
    .description{
        max-width: 211px;
    }
    .home-special-block{
        padding: 29px 0;
        background-size: 120%;
        background-position: -177% 40%;
    }
}
</style>