import Main from '@/components/pages/HomePage.vue';
import ErrorPage from '@/components/pages/404Page.vue';

import { createRouter, createWebHistory, RouterView } from 'vue-router';
import Tr from "@/i18n/translation";


/**
 * Страница получающая массив анкет из vuex
 */

// import AboutPage from '@/components/pages/AboutPage.vue';



const routes = [
  //Для всех
  {
    path: '/:locale?',
    component: RouterView,
    beforeEnter: Tr.routeMiddleware,
    children: [
      {
        name: 'Home',
        path: '',
        component: Main,
      },
      {
        name: 'About',
        path: 'about',
        component: () => import('@/components/pages/AboutPage.vue'),
      },
      {
        name: 'News',
        path: 'news',
        component: () => import('@/components/pages/NewsPage.vue'),
      },
      {
        name: 'Projects',
        path: 'projects',
        component: () => import('@/components/pages/ProjectsPage.vue'),
      },
      {
        name: 'Training',
        path: 'training',
        component: () => import('@/components/pages/TrainingsPage.vue'),
      },
      {
        name: 'Shop',
        path: 'shop',
        component: () => import('@/components/pages/ShopPage.vue'),
      },
      {
        name: 'TrainingPage',
        path: 'training-post/:id',
        component: () => import('@/components/pages/TrainingPostPage.vue'),
      },
      {
        name: 'PostPage',
        path: 'post/:id',
        component: () => import('@/components/pages/PostPage.vue'),
      },
      {
        name: 'ProjectPage',
        path: 'project/:id',
        component: () => import('@/components/pages/ProjectPage.vue'),
      },
      {
        path: 'product/:id',
        name: 'ProductPage',
        component: () => import('@/components/pages/ProductPage.vue'),
      },
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/components/pages/LoginPage.vue'),
      },
      {
        name: 'Account',
        path: 'account',
        component: () => import('@/components/pages/AccountPage.vue'),
      },
      {
        name: 'Cart',
        path: 'cart',
        component: () => import('@/components/pages/CartPage.vue'),
      },
      {
        name: 'Actions',
        path: 'actions',
        component: () => import('@/components/pages/ActionsPage.vue'),
      },
      {
        name: 'Contacts',
        path: 'contacts',
        component: () => import('@/components/pages/ContactsPage.vue'),
      },

      {
        path: '/:pathMatch(.*)*',
        component: ErrorPage
      },

      {
        name: 'Rekvizites',
        path: 'rekvizites',
        component: () => import('@/components/pages/RekvizitesPage.vue'),
      },
      {
        path: 'politic',
        name: 'PoliticPage',
        component: () => import('@/components/pages/PoliticPage.vue'),
      },

      {
        path: 'agreement',
        name: 'AgreementPage',
        component: () => import('@/components/pages/AgreementPage.vue'),
      },

      {
        path: 'wishlist',
        name: 'Wishlist',
        component: () => import('@/components/pages/WishlistPage.vue'),
      },
    ]
  }

]

const router = createRouter({
  mode: 'history',
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})



export default router;
