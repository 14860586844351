<script>
import Tr from "@/i18n/translation";
export default {
  name: "BasketLink",
  props: {
    className: {
      name: String,
      hovered: Boolean,
    },
  },
  data() {
    return {
      Tr: Tr,
    };
  },
  computed: {
    cartCount() {
      return this.$store.getters.getCount;
    },
  },
  watch: {
    cartCount: function () {
      console.log("cartCount", this.cartCount);
      
    },
  },
};
</script>
<template>
  <router-link
    :to="Tr.i18nRoute({ name: 'Cart' })"
    :class="className.name + ' ' + className.hovered + ' basket-icon'"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 6L6 2H18L21 6M3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6M3 6H21M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="count">{{ cartCount > 0 ? cartCount : 0 }}</span>
  </router-link>
</template>
<style scoped>
.basket-icon {
  position: relative;
}
.count {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 16px;
  height: 16px;
  background-color: #000;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.white path {
  stroke: black;
}
.black path,
.true path {
  stroke: white;
}
</style>