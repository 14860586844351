<script>
import btnLink from "../ui/btnLink.vue";
import Tr from "@/i18n/translation";
// import { useI18n } from "vue-i18n";
export default {
  name: "HomeProducts",
  components: {
    btnLink,
  },
  data() {
    return {
      Tr: Tr,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  computed: {
    kategoryes() {
    const priorityOrder = [
      "Трековые",
      "Встраиваемые",
      "Подвесные",
      "Накладные",
      "Светодиодные ленты",
      "Уличные",
    ];
      
    const sortedCategories = [];

    for (let p of priorityOrder){
      for (let k of this.$store.getters.getKategoryes) {
       
        
        if (k.name == p) {
          sortedCategories.push(k);
        }
      }
    }

    return sortedCategories;
  },
  },
  mounted() {},
};
</script>

<template>
  <div class="products-kategories sector">
    <div class="container">
      <div class="products-kategories__content">
        <div class="products-kategories__head">
          <div class="head-h3">{{ $t("home.home-products.title") }}</div>
          <btnLink :link="'Shop'" class="">{{
            $t("home.home-products.btn")
          }}</btnLink>
        </div>
        <div class="products-kategories__items">
          <router-link
            :to="
              Tr.i18nRoute({
                name: 'Shop',
                query: {
                  category: kategory.id,
                },
              })
            "
            class="products-kategories__item"
            v-for="kategory in kategoryes"
            :key="kategory.id"
          >
            <img
              :src="apiDomain + '/web/uploads/' + kategory.pic_alt"
              :alt="kategory.name"
            />
            <div class="head-h4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="17"
                viewBox="0 0 25 17"
                fill="none"
              >
                <path
                  d="M15 4.33203L19.6667 8.9987L15 13.6654"
                  stroke="#111111"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  class="path-stroke"
                  d="M19 9L3 9"
                  stroke="#111111"
                  stroke-width="1.6"
                />
              </svg>
              {{ kategory.name }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.products-kategories__item svg {
  transition: 0.3s all;
  overflow: hidden;
  margin-left: -15px;
}
.products-kategories__item .path-stroke {
  transform: translate(-25px, 0);
  transition: 0.3s all;
}
.products-kategories__item:hover svg {
  margin-left: 0;
}
.products-kategories__item:hover .path-stroke {
  opacity: 1;
  transform: translate(0, 0);
}
.products-kategories__content {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.products-kategories__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products-kategories__items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.products-kategories__item {
  height: 292px;
  width: 24%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 16px;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(13, 17, 32, 0.1);
}
.products-kategories__item img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s;
  height: 100%;
  transform: scale(1.2);
}
.products-kategories__item:hover img {
  transform: scale(1.15);
  transition: all 0.3s;
}
.products-kategories__item:nth-of-type(2),
.products-kategories__item:nth-of-type(4) {
  width: 40%;
}
.products-kategories__item:nth-of-type(3),
.products-kategories__item:nth-of-type(5) {
  width: 32.43%;
}
.head-h4 {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 6px;
}
@media (max-width: 1024px) {
  .products-kategories__items {
    gap: 12px;
  }
  .products-kategories__item {
    height: 164px;
  }
  .products-kategories__item {
    width: 48%;
  }
  .products-kategories__item:nth-of-type(2),
  .products-kategories__item:nth-of-type(4),
  .products-kategories__item:nth-of-type(3),
  .products-kategories__item:nth-of-type(5) {
    width: 48%;
  }
  .products-kategories__content {
    gap: 24px;
  }
}
</style>