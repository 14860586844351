
<script>
import { useCookies } from "vue3-cookies";
import axios from "axios";
import WidjetCart from "./components/ui/WidjetCart.vue";
export default {
  name: "App",
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      cartProduct: false,
      message: false,
    };
  },
  components: {
    WidjetCart,
  },
  methods: {
    checkCookie() {
      let user = this.cookies.isKey("user") ? this.cookies.get("user") : false;
      console.log("UserAPP", user);
      if (user) {
        this.$store.commit("setUserData", user);
      }
      let cartCount = localStorage.getItem("cartCount");
      if (cartCount) {
        this.$store.commit("setCount", cartCount);
      }
      this.getProducts();
    },
    getProducts() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(`${this.apiUrl}api/product/get-product${params}`)
        .then((response) => {
          console.log("get-product", response.data);

          this.$store.commit("setProducts", response.data.products);
        });
    },
  },
  mounted() {
    this.checkCookie();
    setInterval(() => {
      let addCart = localStorage.getItem("addCart");
      let addWishlistNot = localStorage.getItem("addWishlistNot");
      let addWishlist = localStorage.getItem("addWishlist");
      if (addWishlist && !addWishlistNot && !addCart) {
        this.cartProduct = true;
        this.message = "addWishlist";
        return;
      } else {
        this.cartProduct = false;
      }
      if (addWishlistNot && !addWishlist && !addCart) {
        this.message = "addWishlistNot";
        
        this.cartProduct = true;
        return;
        
      } else {
        this.cartProduct = false;
      }
      if (addCart && !addWishlistNot && !addWishlist) {
        this.message = "cart";
        this.cartProduct = true;
        return;
      } else {
        this.cartProduct = false;
      }
     
    }, 50);
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
};
</script>

<template>
  <router-view></router-view>
  <WidjetCart :msg="this.message" v-if="cartProduct" />
</template>

<style>
</style>
