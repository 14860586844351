<script>
import Tr from "@/i18n/translation";
import SearcheLink from "../ui/SearcheLink.vue";
import BasketLink from "../ui/basketLink.vue";
import AccountLink from "../ui/accountLink.vue";
import burgerLinkVue from "../ui/burgerLink.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import axios from "axios";
import { useCookies } from "vue3-cookies";
export default {
  name: "HeaderBlock",
  components: {
    SearcheLink,
    BasketLink,
    AccountLink,
    burgerLinkVue,
    LanguageSwitcher,
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      Tr: Tr,
      className: "white",
      isHovered: false,
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      products: [],
      searchProducts: [],
      kategoryes: [],
      searchKats: [],
      responseKategoryes: [],
      currentBanner: "",
      isFading: false,
      oldPic: null,
      bannerDefault: "all_off.jpg",
      status: false,
    };
  },
  methods: {
    goSearch(status) {
      this.status = status;
      if (this.status) {
        let kategoryes = document.querySelector(".kategoryes");
        kategoryes.classList.remove("active");
        document.querySelector(".searchBlock").classList.add("active");
        this.products = this.$store.getters.getProducts;
        this.searchKats = this.kategoryes;
        document.addEventListener("keyup", this.handleEnterKey);
      } else {
        this.products = this.$store.getters.getProducts;
        this.searchKats = this.kategoryes;
        document.querySelector(".searchBlock").classList.remove("active");
        document.removeEventListener("keyup", this.handleEnterKey);
      }
    },

    handleEnterKey(event) {
      if (event.code === "Enter") {
        this.searchProduct();
      }
    },
    searchProduct() {
      let value = document.querySelector(".searchInput").value;

      this.searchProducts = [];
      for (let p of this.products) {
        let name = p.name.toLowerCase();
        if (name.includes(value.toLowerCase())) {
          this.searchProducts.push(p);
        }
      }
      if (this.searchProducts.length === 0) {
        let product = {
          name: "Ничего не найдено",
          id: 0,
        };
        this.searchProducts.push(product);
      }
    },
    openKategoryes() {
      let kategoryes = document.querySelector(".kategoryes");
      kategoryes.classList.toggle("active");
      document.querySelector(".link-products").classList.toggle("active");
      let links = document.querySelectorAll(".header__links .link");
      links.forEach((link) => {
        if (link.classList.contains("link-products")) {
          link.classList.remove("close");
        } else {
          link.classList.toggle("close");
        }
      });
    },
    headerMax() {
      let header = document.querySelector(".header");
      header.classList.toggle("max");

      if (!header.classList.contains("max")) {
        let kategoryes = document.querySelector(".kategoryes");
        kategoryes.classList.remove("active");
        let searchBlock = document.querySelector(".searchBlock");
        searchBlock.classList.remove("active");
      }
    },
    handleScroll() {
      const mainSection = document.querySelector(".home-main");
      const headerHeight = this.$el.offsetHeight;
      const scrollPosition = window.scrollY + headerHeight;

      if (mainSection) {
        const mainSectionBottom =
          mainSection.offsetTop + mainSection.offsetHeight;

        if (
          scrollPosition >= mainSection.offsetTop &&
          scrollPosition <= mainSectionBottom
        ) {
          this.className = "black";
        } else {
          this.className = "white";
        }
      } else {
        this.className = "white";
      }
    },
    handleMouseEnter() {
      this.isHovered = true;
    },
    handleMouseLeave() {
      this.isHovered = false;
      let kategoryes = document.querySelector(".kategoryes");
      kategoryes.classList.remove("active");
      document.querySelector(".searchBlock").classList.remove("active");
      this.status = false;
    },
    getKategoryes() {
      // eslint-disable-next-line no-unused-vars

      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(this.apiUrl + "api/category/get-category" + params)
        .then((response) => {
          this.responseKategoryes = response.data;
          for (let k of this.responseKategoryes) {
            if (k.name != "Блоки питания") {
              this.kategoryes.push(k);
            } else {
              this.bannerDefault = k.pic;
            }
          }

          // Устанавливаем первый баннер после получения данных
          if (this.kategoryes.length > 0) {
            this.currentBanner = this.bannerDefault;
          }
          this.getBanners(this.kategoryes);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getBanners(kategoryes) {
      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      for (let k of kategoryes) {
        try {
          const response = await axios.get(
            this.apiUrl +
              "api/banner/get-banner-category/&category_id=" +
              k.id +
              params
          );
          k.pic = response.data.banner[0].pic;
          k.pic_alt = response.data.banner[0].pic_alt;
        } catch (error) {
          console.error("Error fetching banner:", error);
        }
      }
      this.kategoryes = kategoryes;
      this.$store.commit("setKategoryes", kategoryes);
    },
    changeBanner(pic) {
      this.isFading = true; // Запускаем анимацию затухания
      setTimeout(() => {
        this.currentBanner = pic;
        this.isFading = false; // Меняем изображение после небольшого задержки
      }, 200); // Задержка должна быть чуть меньше времени анимации

      this.oldPic = pic;
    },
    resetBanner() {
      this.isFading = true;
      setTimeout(() => {
        this.currentBanner = this.bannerDefault;
        this.isFading = false;
      }, 200);
    },
    onImageLoad() {
      this.isFading = false; // Выключаем анимацию после загрузки изображения
    },
    logout() {
      this.cookies.remove("user");
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
    this.getKategoryes();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<template>
  <div
    :class="['header', className, { hovered: isHovered }]"
    @mouseover="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="container">
      <div class="header__container">
        <div class="header__content">
          <router-link :to="Tr.i18nRoute({ name: 'Home' })" class="logoLink">
            <img
              class="logo-white"
              src="../../assets/image/logo-white.svg"
              alt=""
            />
            <img
              class="logo-black"
              src="../../assets/image/logo-black.svg"
              alt=""
            />
          </router-link>
          <div class="nav-links">
            <searche-link
              :className="{ hovered: isHovered, name: className }"
              :status="status"
              @goSearch="goSearch"
            />
            <basket-link :className="{ hovered: isHovered, name: className }" />
            <account-link
              @logout="logout"
              :className="{ hovered: isHovered, name: className }"
            />
            <burger-link-vue
              @openKategoryes="headerMax"
              :className="{ hovered: isHovered, name: className }"
            />
            <LanguageSwitcher />
          </div>
        </div>
        <div class="header__links">
          <div class="links">
            <a @click="openKategoryes" class="link link-products"
              >{{ $t("headerNav.products") }}
              <svg
                class="svg"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
              >
                <path
                  d="M10.5 1L5.83333 5.66667L1.16667 1"
                  stroke="white"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                class="svgMob"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M6 4L10.6667 8.66667L6 13.3333"
                  stroke="white"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M11 8.67188H10" stroke="white" stroke-width="1.6" />
              </svg>
            </a>
            <router-link
              :to="Tr.i18nRoute({ name: 'Training' })"
              class="link"
              >{{ $t("headerNav.training") }}</router-link
            >
            <router-link :to="Tr.i18nRoute({ name: 'News' })" class="link">{{
              $t("headerNav.news")
            }}</router-link>
            <router-link
              :to="Tr.i18nRoute({ name: 'Projects' })"
              class="link"
              >{{ $t("headerNav.projects") }}</router-link
            >
            <router-link :to="Tr.i18nRoute({ name: 'About' })" class="link">{{
              $t("headerNav.about")
            }}</router-link>
          </div>
          <div class="adress">
            ул. Красных Партизан, 98
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M2 7.33334L14.6667 1.33334L8.66667 14L7.33333 8.66668L2 7.33334Z"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="kategoryes">
          <ul class="list">
            <li
              class="li__item"
              v-for="kategory in kategoryes"
              :key="kategory.id"
              :data-banner="kategory.pic"
              @mouseover="changeBanner(kategory.pic)"
              @mouseleave="resetBanner"
            >
              <router-link
                :to="
                  Tr.i18nRoute({
                    name: 'Shop',
                    query: {
                      category: kategory.id,
                    },
                  })
                "
                class="li__link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="17"
                  viewBox="0 0 25 17"
                  fill="none"
                >
                  <path
                    d="M15 4.33203L19.6667 8.9987L15 13.6654"
                    stroke="#858585"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    class="path-stroke"
                    d="M19 9L3 9"
                    stroke="#858585"
                    stroke-width="1.6"
                  />
                </svg>
                {{ kategory.name }}
              </router-link>
            </li>
          </ul>
          <div class="banner">
            <img
              :src="apiDomain + '/web/uploads/' + currentBanner"
              alt="Banner"
              :class="{ fade: isFading }"
              @load="onImageLoad"
            />
          </div>
        </div>
        <div class="searchBlock">
          <div class="search">
            <input
              type="text"
              placeholder="Поиск по товарам"
              class="searchInput"
            />
            <a @click="searchProduct" class="go-search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 21L16.7 16.7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                  stroke="#111111"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div class="kategorySearch__list">
            <router-link
              v-for="kategory in kategoryes"
              :key="kategory.id"
              :to="
                Tr.i18nRoute({
                  name: 'Shop',
                  query: {
                    category: kategory.id,
                  },
                })
              "
              class="kategorySearch__item"
              >{{ kategory.name }}</router-link
            >
          </div>
          <div v-if="searchProducts.length > 0" class="product_lists">
            <router-link
              v-for="product in searchProducts"
              :key="product.id"
              :to="{
                name: 'ProductPage',
                params: { id: product?.group?.group_id || product?.id },
                query: { p: product?.group?.group_id ? undefined : 'true' },
              }"
              class="product_lists__item"
              >{{ product.name }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header.white .logo-white {
  display: none;
}
.header.black .logo-black {
  display: none;
}
.kategoryes {
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px;
}
.searchBlock {
  opacity: 0;
  visibility: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 0.3s;
  padding-bottom: 0;
}
.searchBlock.active {
  height: max-content;
  opacity: 1;
  transition: all 0.3s;
  visibility: visible;
  padding-bottom: 121px;
}
.search {
  width: 100%;
  position: relative;
}
.searchInput {
  width: 100%;
  padding: 16px 20px;
  border-radius: 2px;
  background: var(--White, #fff);
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  outline: none;
  border: none;
}
.searchInput::placeholder {
  color: var(--Black-600, #b0b0b0);
}
.searchInput:focus {
  outline: none;
  border: none;
}
.go-search {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}
.kategorySearch__item {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%; /* 19.52px */
  padding: 12px 20px;
  border-radius: 2px;
  background: var(--Black-1200, #2e2e2e);
}
.kategorySearch__list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.product_lists {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.product_lists__item {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.kategoryes.active {
  height: max-content;
  opacity: 1;
  transition: all 0.3s;
  visibility: visible;
  padding-bottom: 121px;
}
.banner {
  width: 100%;
  max-width: 947px;
  height: 335px;
}
.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
  opacity: 1;
}
.banner img.fade {
  opacity: 0.7;
}
.kategoryes.active .list {
  opacity: 1;
  visibility: visible;
}
.link {
  color: var(--White, #fff);

  font-family: "Suisse Intl";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0;
  transition: all 0.3s;
}

.header__container {
  display: flex;
  flex-direction: column;
}
.header__links {
  display: flex;
  justify-content: space-between;
  gap: 36px;
  align-items: center;
  padding: 26px 0 29px;
  transform: translateY(-100%);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  border-top: 1px solid #6a6a6a;
  margin-top: 23px;
}
.links {
  display: flex;
  gap: 36px;
}
.adress {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  display: flex;
  gap: 12px;
  align-items: center;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}

.header {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(17px);
  padding-top: 22px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 73px;
  overflow: hidden;
  transition: all 0.3s;
}
.header.black {
  background: rgba(17, 17, 17, 0.18);
  backdrop-filter: blur(17px);
  transition: all 0.3s;
}
.header.hovered {
  background: rgba(12, 12, 12, 0.9);
  backdrop-filter: blur(40px);
  overflow: visible;
  transition: all 0.3s;
}
.header.hovered .logo-black {
  display: none;
}
.header.hovered .logo-white {
  display: block;
}
.header.hovered .link,
.header.hovered .adress {
  color: #fff;
}
.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nav-links {
  display: flex;
  flex-direction: row;
  gap: 18px;
}
.li__link {
  display: flex;
  align-items: center;
  gap: 6px;
}
.link-products {
  display: flex;
  gap: 10px;
  align-items: center;
}
.link-products.active .svg {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.link-products .svg {
  transition: all 0.3s;
}
.link-products .svgMob {
  display: none;
}
@media screen and (min-width: 1028px) {
  .header:hover .link {
    opacity: 1;
    transition: all 0.3s;
  }
  .header:hover .header__links,
  .header:hover .adress {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .header:hover {
    height: max-content;
  }
}
@media screen and (max-width: 1024px) {
  .header {
    padding: 22px 0;
  }
  .header.max .link {
    opacity: 1;
    transition: all 0.3s;
  }
  .header.max .header__links,
  .header.max .adress {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .header.max {
    height: max-content;
  }
  .link,
  .li__link {
    font-size: 16px;
  }
  .links {
    gap: 28px;
  }
}
@media screen and (max-width: 650px) {
  .header__links {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .links {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
  .link {
    padding: 14px 3px;
    font-size: 22px;
    opacity: 1;
    height: max-content;
  }
  .header.max .link.close {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  .link-products .svgMob {
    display: block;
  }
  .link-products .svg {
    display: none;
  }
  .link-products {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .banner {
    display: none;
  }
}
</style>